import { LocalizationKey } from "../localization/Localization";

export type AudioModel = {
    description ?: LocalizationKey;
    title: LocalizationKey,
    name: LocalizationKey,
    audio: LocalizationKey,
}

export const page1Entries: AudioModel[] = Array.from({ length: 1 }, (_, index) => ({
    title: `page1_item${index}$title` as LocalizationKey,
    name: `page1_item${index}$name` as LocalizationKey,
    audio: `page1_item${index}$audio` as LocalizationKey,
}));

export const page2Entries: AudioModel[] = Array.from({ length: 43 }, (_, index) => ({
    title: `page2_item${index}$title` as LocalizationKey,
    name: `page2_item${index}$name` as LocalizationKey,
    audio: `page2_item${index}$audio` as LocalizationKey,
    description: `page2_item${index}$description` as LocalizationKey,
}));

export const page3AudioEntries: AudioModel[] = Array.from({ length: 11 }, (_, index) => ({
    title: `page3_item${index}$title` as LocalizationKey,
    name: `page3_item${index}$name` as LocalizationKey,
    audio: `page3_item${index}$audio` as LocalizationKey,
}));
